import {CanActivate, Router} from '@angular/router';
import {LoginService} from '../shared/services/login.service';
import {Injectable} from '@angular/core';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: LoginService, private router: Router) { }

  canActivate(): any {
    const token = sessionStorage.getItem('_userToken');
    if (!token) {
      this.router.navigate(['/']).then(r => {
      });
      return false;
    }
    return true;
  }
}
